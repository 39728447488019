<template>
    <div>
        <modal-field
            label="Transport mode"
            required
            class="mb-4 w-full"
        >
            <multiselect
                v-model="priceLeadtime.transport_mode"
                :options="transportModes"
                placeholder="Select"
                class="w-48"
            />
        </modal-field>
    </div> 
</template>

<script>
export default {
    computed: {
        priceLeadtime() {
            return this.$store.state['price-leadtime'];
        },
        transportModes() {
            return this.$store.getters['auth/modes']
        },
    },
}
</script>