<template>
    <div class="h-full price-leadtime-form" style="margin: -24px -24px -24px -32px;">
        <div ref="overflow-container" class="h-full overflow-y-auto" style="padding: 24px 24px 24px 32px;">
            <div class="relative flex w-full pb-3 mb-6 overflow-y-visible border-b header-tabs">
                <div class="absolute flex buttons-container smooth-transition" :class="{'slide-left' : slideLeft}">
                    <button class="mr-2" :disabled="isFirstStep" @click="prevStep()">
                        <fa :icon="['fal','arrow-left']" />
                    </button>

                    <button
                        v-for="(step, index) in steps"
                        :key="step.key"
                        class="px-2 mr-2 text-gray-600"
                        :disabled="currentStep !== index"
                        :class="{'border-b-2 pb-3 -mb-3 active text-gray-900 disabled': currentStep === index}"
                    >
                        {{ index+1 }}. {{ step.title }}
                    </button>

                    <button class="mr-2" :disabled="isLastStep" @click="nextStep()">
                        <fa :icon="['fal','arrow-right']" />
                    </button>
                </div>

                <queries class="queries-pl"></queries>
            </div>

            <transition name="side" mode="out-in">
                <template >
                    <price-leadtime-address v-if="currentStepKey === 'address'" :proxio="proxio" :organizations="organizations" />
                    <goods v-if="currentStepKey === 'goods' || currentStepKey === 'finalize_goods_description'" class="pb-10 pr-6" />

                    <result
                        v-if="currentStepKey === 'price_leadtime'"
                        @proxio-loading-start="loadingProxio = true"
                        @proxio-loading-end="loadingProxio = false"
                        @start-over="showStartOverModal = true"
                    />

                    <price-leadtime-address v-if="currentStepKey === 'create_booking'" :organizations="organizations" />
                    <goods v-if="currentStepKey === 'finalize_goods_description'" class="pr-6" />
                    <delivery-terms v-if="currentStepKey === 'delivery_terms'" class="pr-6" />
                    <transport-mode v-if="currentStepKey === 'transport_mode'" class="pr-6" />
                </template>
            </transition>
            
            <start-over-modal :show="showStartOverModal" @start-over="startOverFail" />

            <div style="padding-bottom: 80px"></div>
        </div>

        <div
            class="absolute bottom-0 left-0 right-0 flex items-center justify-center py-8 px-10 bg-white border-t-2 footer-wizard"
            style="margin-bottom: -48px;"
        >
            <div :class="{'mx-auto' : currentStep < 3}">
                <button
                    :class="{ 'btn-hidden': currentStep === 0 }"
                    :disabled="currentStep === 0 || loadingUnifaun"
                    @click="prevStep()" class="btn">Previous
                </button>

                <span class="mx-8">Step {{ isShortFlow && currentStep === 5 ? 3 : currentStep+1 }}/{{stepsLength}}</span>
                <button v-if="!isLastStep || isLastStep && !isPltFlow && !isAdHocFlow" :class="{ 'btn-hidden': currentStep >= 6 }" :disabled="!showNextButton || loadingUnifaun" @click="nextStep()" class="btn">
                    <span v-show="!isLastStep">Next</span>
                    <span v-show="isLastStep">Create booking</span>
                </button>
                <button v-if="currentStep === 2 && !isPltFlow" type="button" class="btn ad-hoc-btn" :disabled="loadingProxio" @click="createBookingInstantDraft">
                    <span :class="{ 'opacity-0': loadingUnifaun }">Ad-hoc request</span>
                </button>

                <button v-if="currentStepKey === 'goods' && isAdHocFlow" type="button" class="btn" :disabled="loadingAdHoc" @click="addHocModeRequest">
                    <span>Send request</span>
                </button>
            </div>
            <!-- <button v-if="currentStep < 2 && !isShortFlow" class="absolute right-0 mr-28 btn" @click="commitAndGo">Calculate</button> -->
        </div>

        <modal :show="showDimensionsModal" @hide="showDimensionsModal = false" size="sm">
            <h2 slot="header">Package dimensions</h2>
            <div slot="body" class="">
                <p>Dimensions of the goods can have large impact on available services and freight price. So if I were you I would fill those in too.
                </p>
                <div class="flex items-center my-4">
                    <input
                        id="preventDimensions"
                        type="checkbox"
                        v-model="preventDimensionPopup"
                        class="mr-2"
                    > <label for="preventDimensions">Got it, I know all this. Please don't remind me again.</label>
                </div>
            </div>

            <div slot="footer" class="flex w-full">
                <button
                    class="ml-auto mr-4 text-sm btn-transparent"
                    @click="showDimensionsModal = false"
                >Fill out dimensions</button>
                <button class="btn" @click="skipDimensions()">Carry on</button>
            </div>
        </modal>

        <modal :show="showPriceLeadtimeResetModal" @hide="showPriceLeadtimeResetModal = false" size="sm">
            <h2 slot="header">Are you sure?</h2>
            <div slot="body" class="">
                <p>
                    If you go back, the booking process will start over and you need to do another calculation. Is that what you want?
                </p>
            </div>

            <div slot="footer" class="flex w-full">
                <button class="ml-auto mr-4 text-sm btn-transparent" @click="showPriceLeadtimeResetModal = false">No, stay here</button>
                <button class="btn" @click="startOver()">Yes, start over</button>
            </div>
        </modal>

        <modal
            :show="showBookingModal"
            :closeable="true"
            :click-to-close="false"
            :escapeable="false"
            size="sm"
            @hide="hideBookdingModal"
        >
            <h2 slot="header">Booking created</h2>
            <div slot="body">
                <p v-text="bookingText" class="mb-4" />
                <div v-if="bookingData" class="mb-2">
                    <p>
                        <span>Consignment id: </span>
                        <component
                            :is="apportServiceLink ? 'a' : 'span'"
                            :href="apportServiceLink"
                            target="_blank"
                            :class="{ 'text-transparent linear-background': loadingUnifaun }"
                        >
                            {{ bookingData.consignmentId }}
                        </component>
                    </p>
                    <p v-text="`Order no: ${bookingData.orderNo || '-'}`" class="mt-2" />
                </div>
            </div>
            <div slot="footer" class="flex flex__column flex__align-center w-full justify-center">
                <p v-if="user.azure_id" class="flex w-full justify-center px-2">
                    <component
                        :is="loadingUnifaun ? 'div' : 'a'"
                        :href="apportServiceLink || apportSsoLink"
                        :disabled="loadingUnifaun"
                        target="_blank"
                        class="warning-link btn inline-block height-50"
                    >
                        Open created booking
                    </component>
                </p>
                <div class="warning-text border-red-500 px-6 py-1 text-center mt-4 bg-orange-200">
                    <p>
                        In case you are facing vOne login page, you may
                    </p>
                    <p class="flex justify">
                        <a :href="apportSsoLink" target="_blank" class="flex w-full justify-center flex__align-center">
                            click here to authorize with Azure <AzureIcon />
                        </a>
                    </p>
                    <p>
                        or enter ordinary vOne credentials.
                    </p>
                    <p>
                        On authorization you can click the button again
                    </p>
                    <h4 class="warning-header uppercase text-red-500 mt-4">Warning</h4>
                    <p>
                        Please note price may alter in case you will
                    </p>
                    <p>
                        change booking details in vOne
                    </p>
                </div>
                <button class="btn btn-primary mt-4" @click="hideBookdingModal">
                    Home page
                </button>
            </div>
        </modal>

        <modal
            :show="showBookingInstantModal"
            :closeable="true"
            :click-to-close="false"
            size="sm"
            @hide="hideBookdingInstantModal"
        >
            <h2 slot="header">Request created</h2>
            <div slot="body" class="pb-4">
                <p>
                    We have received your ad-hoc booking request. Our operational team will reach you soon via e-mail, specified in your ONE account.
                </p>
            </div>

            <div slot="footer" class="flex justify-end w-full">
                <button class="btn" @click="hideBookdingInstantModal">Done</button>
            </div>
        </modal>

        <modal
            :show="showAdHocModal"
            :closeable="true"
            :click-to-close="false"
            size="sm"
            @hide="hideAdHocModal"
        >
            <h2 slot="header">Request created</h2>
            <div slot="body" class="pb-4">
                <p>
                    You have successfully created ad-hoc booking request! Our control tower will take care of it. In case of need we will get in contact with you to clarify missing details.
                </p>
            </div>
            <div slot="footer" class="flex justify-end w-full">
                <button class="btn" @click="hideAdHocModal">Done</button>
            </div>
        </modal>

        <modal :show="azureModals.warning.show" :closeable="false" size="sm" @hide="azureModals.warning.show = false">
            <h2 slot="header" v-text="azureModals.warning.header" />
            <div slot="body" class="pb-6">
                <p v-text="azureModals.warning.body" />
            </div>
            <div slot="footer" class="flex flex__justify-center w-full">
                <button type="button" class="btn" :class="{ 'btn-loading': isAzureInviting }" @click="inviteAzure">
                    <span :class="{ 'opacity-0': isAzureInviting }">
                        Invite me to Azure
                    </span>
                </button>
                <button type="button" class="btn-transparent ml-4" @click="azureModals.warning.show = false">
                    Skip
                </button>
            </div>
        </modal>

        <modal :show="azureModals.inviteSuccess.show" :closeable="false" size="sm" @hide="closeAzureModals">
            <h2 slot="header" v-text="azureModals.inviteSuccess.header" />
            <div slot="body" class="pb-6">
                <p v-text="azureModals.inviteSuccess.body" />
            </div>
            <div slot="footer" class="flex flex__justify-center w-full">
                <button type="button" class="btn-transparent ml-4" @click="closeAzureModals">
                    OK
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
    import Modal from '~/components/Modal.vue';
    import PriceLeadtimeAddress from './price-leadtime/PriceLeadtimeAddress.vue';
    import DeliveryTerms from './price-leadtime/DeliveryTerms.vue';
    import Goods from "./price-leadtime/Goods";
    import Result from "./price-leadtime/Result";
    import TransportMode from './price-leadtime/TransportMode.vue';
    import axios from "axios";
    import { requestErrors } from '~/utils/errors';
    import AzureIcon from '~/components/icons/azure-icon.vue';
    import StartOverModal from '~/components/PriceLeadTime/ModalStartOver.vue';

    import Queries from "./price-leadtime/Queries";
    import PltMixin from './pltMixin';
    import { FLOW_TYPE_MAP } from '~/store/modules/price-leadtime';

    export default {
        name: "priceLeadtime",

        mixins: [PltMixin],

        metaInfo() {
            return { title: "Price & Leadtime" };
        },

        components: {
            Queries,
            Goods,
            DeliveryTerms,
            Modal,
            Result,
            PriceLeadtimeAddress,
            AzureIcon,
            StartOverModal,
            TransportMode,
        },

        data: () => ({
            showStartOverModal: false,
            showDimensionsModal: false,
            preventDimensionPopup: false,
            showPriceLeadtimeResetModal: false,
            showAdHocModal: false,
            slideLeft: false,
            
            showBookingInstantModal: false,
            loadingProxio: false,
            loadingAdHoc: false,
        }),

        watch: {
            currentStep() {
                this.loadingProxio = false;
                this.$nextTick(() => {
                    this.navbarSlideLeft()
                    this.$refs['overflow-container']?.scrollTo(0, 0);
                });
            },
            $route(val, oldVal) {
                if (val.name === oldVal.name) {
                    return;
                }

                this.init();
            },
        },

        computed: {
            priceLeadTimeStore() {
                return this.$store.state['price-leadtime'];
            },
            dataAddress() {
                return this.$store.getters[`price-leadtime/address`]
            },
            stepsLength() {
                return this.steps.length;
            },
            steps() {
                return this.$store.getters['price-leadtime/activeSteps']
            },
            bookingModule() {
                return this.$store.getters['price-leadtime/bookingModule']
            },
            isPltFlow() {
                return this.$store.getters['price-leadtime/isPltFlow']
            },
            isAdHocFlow() {
                return this.$store.getters['price-leadtime/isAdHocFlow']
            },
            step() {
                const result =  this.$store.getters['price-leadtime/activeSteps'][this.currentStep];

                return result;
            },
            currentStepKey() {
                return this.steps[this.currentStep].key
            },
            currentValidationEndpoint() {
                return this.steps[this.currentStep].validationEndpoint
            },
            currentStepDataKey() {
                return this.steps[this.currentStep].dataKey
            },
            isFirstStep() {
                return this.currentStep === 0;
            },
            isLastStep() {
                if (! this.bookingModule && this.currentStep >= 5) {
                    return true
                }

                return this.currentStep === this.stepsLength - 1;
            },

            currentStep() {
                return this.$store.getters["price-leadtime/currentStep"];
            },
            showNextButton() {
                if (this.currentStep === 0) {
                    return this.validateAddress;
                }

                if (this.currentStep === 1) {
                    return this.validateGoods;
                }

                if (this.currentStep === 2 && this.isShortFlow) {
                    return Boolean(this.priceLeadTimeResult?.carrier_service_model?.id);
                }

                if (this.currentStep === 2 && !this.isShortFlow) {
                    return Boolean(this.priceLeadTimeResult);
                }

                if (this.currentStep === 3) {
                    return this.validateCreateBooking;
                }

                if (this.currentStep === 4) {
                    return this.validatePriceLt;
                }

                if (this.currentStepKey === 'summary') {
                    return false;
                }

                if (this.currentStepKey === 'confirmation') {
                    return false;
                }

                if (this.isLastStep) {
                    return Boolean(this.deliveryTerms.senders_reference);
                }

                return true;
            },
            goods() {
                return this.$store.getters["price-leadtime/goods"];
            },
            shipmentDetails() {
                return this.$store.getters["price-leadtime/shipmentDetails"];
            },
            uuid() {
                return this.$route.query.uuid;
            },
            authSites() {
                return this.$store.getters['auth/sites'];
            },
            isGodUser() {
                return this.$store.getters['auth/isGodUser'];
            },
            user() {
                return this.$store.getters['auth/user'];
            },
        },
        methods: {
            startOverFail() {
                window.scrollTo(0, 0);
                this.$store.commit('price-leadtime/SET_CURRENT_STEP', 0);

                this.showStartOverModal = false;
            },
            startOver() {
                this.shipmentDetails.payment_details.account_number = null
                this.goodsData.forEach(item => item.package_type = null);

                this.goToStep(1)
            },
            navbarSlideLeft() {
                if(window.innerWidth <= 1400 && this.currentStep > 1) {
                    this.slideLeft = true;
                } else {
                    this.slideLeft = false;
                }
            },
            goToStep(index) {
                if (! this.bookingModule && this.currentStep > 6) {
                    return false;
                }


                this.showPriceLeadtimeResetModal = false
                window.scrollTo(0, 0);
                this.$store.commit('price-leadtime/SET_CURRENT_STEP', index)
                this.$forceUpdate();
            },
            prevStep() {
                if (this.isFirstStep) {
                    return;
                }

                if (this.currentStep === 5 && this.isShortFlow) {
                    this.goToStep(1);

                    return;
                }

                if (this.currentStep === 3) {
                    this.showPriceLeadtimeResetModal = true;
                    return;
                }

                if (this.currentStep === 2) {
                    this.$store.commit('price-leadtime/UPDATE_PRICE_LEAD_TIME_RESULT', null);
                }

                this.goToStep(this.currentStep - 1)
                this.navbarSlideLeft();
            },
            validate(step, data) {
                if (step === 'address') {
                    const result = [];

                    result.push(Boolean(data.delivery.zip && data.delivery.country));

                    result.push(Boolean(data.pickup.zip && data.pickup.country && data.pickup.pickup_date));

                    return result.every(item => { return !!item });
                }

                if (step === 'goods') {
                    return data.items.every(item => { return item.units });
                }

                return true;
            },
            async nextStep(checkDimensions = true) {
                const validateData = this.$store.getters['price-leadtime/validation'][this.currentStepDataKey]

                const valid = this.validate(this.currentValidationEndpoint, validateData);

                if (!valid) {
                    this.$snotify.error('Validation failed. Please review your input');

                    return;
                }

                if(this.currentStep === 1 && checkDimensions) {
                    this.dimensionsCheck();
                    return
                }

                if (this.isLastStep) {
                    await this.createBookingDraft();

                    if (this.bookingData?.consignmentId) {
                        this.fetchUnifaunUrl();
                    }

                    return;
                }

                if (this.currentValidationEndpoint === 'invoice-details') {
                    this.step.errors = {}
                    this.goToStep(this.currentStep + 1)
                    this.navbarSlideLeft();
                    this.$forceUpdate();

                    return;
                }

                this.step.errors = {}
                this.goToStep(this.currentStep + 1)
                this.navbarSlideLeft();
                this.$forceUpdate();
            },
            async createBookingInstantDraft() {
                this.loadingUnifaun = true;

                const payloadData = this.getBookingData();

                try {
                    const { data: { data } } = await axios.post(this.$apiUrl.apport.draftBase, payloadData);

                    if (data.consignmentId) {
                        this.showBookingInstantModal = true;

                        return;
                    }

                    if (data?.errors?.length) {
                        data.errors.forEach(error => this.$snotify.error(error));
                    }
                } catch (error) {
                    this.$snotify.error(requestErrors(error));
                }

                this.loadingUnifaun = false;
            },
            async addHocModeRequest() {
                this.loadingAdHoc = true;

                const payloadData = this.getBookingData();

                try {
                    const { data: { data } } = await axios.post(this.$apiUrl.apport.adHoc, payloadData);

                    if (data.consignmentId) {
                        this.showAdHocModal = true;

                        return;
                    }

                    if (data?.errors?.length) {
                        data.errors.forEach(error => this.$snotify.error(error));
                    }
                } catch (error) {
                    this.$snotify.error(requestErrors(error));
                } finally {
                    this.loadingAdHoc = false;
                }
            },
            hideBookdingInstantModal() {
                this.showBookingInstantModal = false;
                this.$store.commit('price-leadtime/resetState');
            },
            hideAdHocModal() {
                this.showAdHocModal = false;
                this.loadingAdHoc = false;
                this.$store.commit('price-leadtime/SET_CURRENT_STEP', 0)
                this.$store.commit('price-leadtime/resetState')
                this.$store.commit('price-leadtime/SET_FLOW_TYPE', FLOW_TYPE_MAP.adHoc);
            },
            dimensionsCheck() {
                if(this.preventDimensionPopup === true) {
                    this.nextStep(false);
                    return;
                }

                let foundErrors = false;

                this.goods.items.forEach( line => {
                    const keys = Object.keys(line);
                    const dimensionKeys = ['weight', 'length', 'height', 'width', 'volume'];

                    if (line.goods_type) {
                        if (line.goods_type.toLowerCase() === 'box/parcel' || line.goods_type.toLowerCase() === 'envelope') {
                            keys.forEach(key => {
                                if (dimensionKeys.includes(key)) {
                                    if (line[key] == null || line[key] === "") {
                                        foundErrors = true;
                                    }
                                }
                            });
                        }
                    }
                });

                if (foundErrors) {
                    this.showDimensionsModal = true;
                }

                if(this.showDimensionsModal === false) {
                    this.nextStep(false);
                }
            },
            skipDimensions() {
                this.showDimensionsModal = false;
                this.nextStep(false);
            },
            commitAndGo() {
                this.goToStep(2)
            },
            init() {
                this.$store.commit('price-leadtime/SET_CURRENT_STEP', 0)
                this.$store.commit('price-leadtime/resetState')

                let flowType = FLOW_TYPE_MAP.quote;

                if (this.$route.name === 'transport.transport-booking.form') {
                    flowType = FLOW_TYPE_MAP.short;
                }

                if (this.$route.name === 'transport.priceleadtime.standalone') {
                    flowType = FLOW_TYPE_MAP.plt;
                }

                if (this.$route.name === 'transport.transport-booking.ad-hoc') {
                    flowType = FLOW_TYPE_MAP.adHoc;
                }

                this.$store.commit('price-leadtime/SET_FLOW_TYPE', flowType);
            },
        },

        async created() {
            this.init();

            this.checkAzureId();
            await this.fetchProxio();
            await this.fetchOrganizations();
        },
    };
</script>
<style lang="scss">
.price-leadtime-form {
    .ad-hoc-btn {
        position: absolute;
        right: 24px;
    }
    .text-transparent {
        cursor: default;
    
        &:hover {
            color: transparent;
        }
    }

    .warning {
        &-text {
            border-width: 1px;
            border-style: solid;
            border-radius: 8px;
        }
        &-header {
            font-weight: 800;
        }
        &-link {
            line-height: 27px;
        }
    }

    div.warning-link {
        @apply bg-gray-500 border-gray-500 #{!important};
    }

    .height-50 {
        height: 50px;
    }
}
</style>
